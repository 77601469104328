
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Progress } from "vant";

@Component({
  components: {
    [Progress.name]: Progress,
  },
})
export default class StatusBar extends Mixins(Mixin) {
  @Prop({ type: String, default: "rgba(0,0,0,0)" })
  private BgStyle!: string;
  public barHeight = 0;
  public bg = "";
  mounted(): void {
    this.barHeight = this.getStatusBarHeight() * 0.01;
    this.bg = this.BgStyle;
  }
  /**
   * 获得状态栏高度
   */
  getStatusBarHeight(): number {
    var immersed = 0;
    var ms = /Html5Plus\/.+\s\(.*(Immersed\/(\d+\.?\d*).*)\)/gi.exec(
      navigator.userAgent
    );
    if (ms && ms.length >= 3) {
      // 当前环境为沉浸式状态栏模式
      immersed = parseFloat(ms[2]); // 获取状态栏的高度
    }
    return immersed;
  }
}
